// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---gatsby-theme-didactic-adventure-src-pages-about-tsx": () => import("./../../../gatsby-theme-didactic-adventure/src/pages/about.tsx" /* webpackChunkName: "component---gatsby-theme-didactic-adventure-src-pages-about-tsx" */),
  "component---gatsby-theme-didactic-adventure-src-templates-article-template-tsx": () => import("./../../../gatsby-theme-didactic-adventure/src/templates/article.template.tsx" /* webpackChunkName: "component---gatsby-theme-didactic-adventure-src-templates-article-template-tsx" */),
  "component---gatsby-theme-didactic-adventure-src-templates-articles-template-tsx": () => import("./../../../gatsby-theme-didactic-adventure/src/templates/articles.template.tsx" /* webpackChunkName: "component---gatsby-theme-didactic-adventure-src-templates-articles-template-tsx" */),
  "component---gatsby-theme-didactic-adventure-src-templates-author-template-tsx": () => import("./../../../gatsby-theme-didactic-adventure/src/templates/author.template.tsx" /* webpackChunkName: "component---gatsby-theme-didactic-adventure-src-templates-author-template-tsx" */),
  "component---gatsby-theme-didactic-adventure-src-templates-country-template-tsx": () => import("./../../../gatsby-theme-didactic-adventure/src/templates/country.template.tsx" /* webpackChunkName: "component---gatsby-theme-didactic-adventure-src-templates-country-template-tsx" */),
  "component---gatsby-theme-didactic-adventure-src-templates-destinations-template-tsx": () => import("./../../../gatsby-theme-didactic-adventure/src/templates/destinations.template.tsx" /* webpackChunkName: "component---gatsby-theme-didactic-adventure-src-templates-destinations-template-tsx" */)
}

