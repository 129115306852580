import merge from 'lodash/merge'

import colors from './colors'
import tags from './tags'

export const breakpoints = [
  ['phone_small', 320],
  ['phone', 376],
  ['phablet', 540],
  ['tablet', 735],
  ['desktop', 1070],
  ['desktop_medium', 1280],
  ['desktop_large', 1440]
]

const fonts = {
  serif: "'Merriweather', Georgia, Serif",
  sansSerif:
    "'SF Pro Display', '-apple-system', 'BlinkMacSystemFont', 'San Francisco', 'Helvetica Neue', 'Helvetica', 'Ubuntu', 'Roboto', 'Noto', 'Segoe UI', 'Arial', sans-serif",
  monospace: '"Operator Mono", Consolas, Menlo, Monaco, source-code-pro, Courier New, monospace'
}

const colorModeTransition =
  'background 0.25s var(--ease-in-out-quad), color 0.25s var(--ease-in-out-quad)'

const cards = {
  primary: {
    bg: 'background',
    color: 'text',
    borderRadius: '8px',
    overflow: 'hidden',
    p: 3,
    width: '100%',
    padding: '0',
    cursor: 'pointer',
    boxShadow: '0 0 8px rgba(0, 0, 0, 0.125)'
  },
  shadow: {
    variant: 'cards.primary',
    transition: 'box-shadow 0.2s ease',
    boxShadow: 'small',
    ':hover,:focus': {
      boxShadow: 'medium'
    }
  }
}

export default merge({
  initialColorMode: 'light',
  useCustomProperties: true,
  colorModeTransition,
  colors,
  fonts,
  cards,
  breakpoints: [
    '22.5rem', // 360
    '25rem', // 400
    '30rem', // 480
    '37.5rem', // 600
    '45rem', // 720
    '52.5rem', // 840
    '60rem', // 960
    '64rem', // 1024
    '80rem', // 1280
    '90rem', // 1440
    '100rem', // 1600
    '120rem' // 1920
    // "20rem",",
    // "23.5rem",
    // "33.75rem",
    // "45.938rem",
    // "66.875rem",
    // "80rem",
  ],
  // breakpoints: ['43em', '62em', '82em'],
  tags
})
