/* eslint-disable */

function handleAccessibilityFocus() {
  const elementsWithA11yFocus = [...document.querySelectorAll('[data-a11y]')];

  document.addEventListener('keyup', event => {
    elementsWithA11yFocus.forEach(element => {
      if (element === event.target || element.contains(event.target)) {
        element.setAttribute('data-a11y', 'true');
      } else {
        element.setAttribute('data-a11y', 'false');
      }
    });
  });

  // On mouse click change data-a11y attribute false
  document.addEventListener('mousedown', event => {
    elementsWithA11yFocus.forEach(element => {
      if (element === event.target || element.contains(event.target)) {
        element.setAttribute('data-a11y', 'false');
      }
    });
  });
}
function scrollToAnchor(location, mainNavHeight = 0) {
  // Check for location so build does not fail
  if (location && location.hash) {
    const item = document.querySelector(`${location.hash}`)?.offsetTop

    window.scrollTo({
      top: item + 1190, // nav height
      // top: item + 890, // nav height
      behavior: "smooth",
    })
  }

  return true
}

module.exports = ({ prevLocation, location }) => {
  handleAccessibilityFocus();
  scrollToAnchor(location);

  if (prevLocation) {
    localStorage.setItem('previousPath', prevLocation.pathname);
  }
};
