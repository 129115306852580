/* eslint-disable */

module.exports = ({ routerProps, prevRouterProps, getSavedScrollPosition }) => {
  const fixedScrollPaths = [
    "/destinations",
    "/europe",
    "/asia",
    "/africa",
    "/north-america",
    "/south-america",
    "/australia"
  ];
  const { pathname } = routerProps.location;

  if (fixedScrollPaths.includes(pathname)){
    return false;
  }
  return true;
};
